import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import Alert from "@mui/material/Alert";
import { Button } from "react-bootstrap";
import styles from "./signup.module.css";
import { getAuth } from "firebase/auth";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { useUserAuth } from "../../context/userAuthContext";

const Signup = () => {
  // const { currentUser } = useAuth();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const password = "universal password";
  // const { signUp } = useUserAuth();
  const navigate = useNavigate();
  const { logIn } = useUserAuth();
  // const { user } = useUserAuth();
  // const { setTimeActive } = useAuthValue();
  // let navigate = useNavigate();

  // const sendEmail = () => {
  //   auth.then((userCredential) => {
  //     userCredential.user.sendEmailVerification();
  //     auth.logOut();
  //     console.log("send email verification");
  //   })
  //     .catch(alert);
  // }

  function Error(err) {
    if (err.toString().includes("auth/missing-email")) {
      // return alert("Please enter a valid email address");
      setError("Please enter a valid email address");
    } else if (err.toString().includes("auth/weak-password")) {
      // return alert("Password should be at least 6 characters");
      setError("Password should be at least 6 characters");
    } else if (err.toString().includes("auth/internal-error")) {
      // return alert("Please enter both email and password");
      setError("Please enter both email and password");
    } else if (err.toString().includes("auth/invalid-email")) {
      // return alert("Please enter a valid email address");
      setError("Please enter a valid email address");
    } else if (err.toString().includes("auth/email-already-in-use")) {
      // return alert("Please enter a valid email address");
      setError(
        "There already is an account associated with this email. Please go to log in.",
      );
    } else {
      // return alert(err);
      setError(err);
    }
  }

  function validateEmail(e) {
    setEmail(e.target.value);
    console.log(email);
    if (email.includes(".") && !email.includes("@")) {
      setError(
        "Please use school-issued email in the formate of your_Tufts_ID@tufts.edu",
      );
    }
  }

  const handleSubmit = async (e) => {
    setError("");
    e.preventDefault();

    if (email.substring(0, email.indexOf("@")).includes(".")) {
      setError(
        "Please use school-issued email in the formate of your_Tufts_ID@tufts.edu",
      );
      return;
    }

    try {
      await logIn(email, password);
    } catch (err) {
      if (err.toString().includes("auth/wrong-password")) {
        setError(
          "Wrong email or password. If you created an account before Nov 2024, the way to authenticate has changed. Trying to work on allowing your account to login.",
        );
        return;
      } else if (err.toString().includes("auth/user-not-found")) {
        createUserWithEmailAndPassword(getAuth(), email, password)
          .then((userCredential) => {
            sendEmailVerification(getAuth().currentUser)
              .then(() => {
                navigate("/verify-email");
              })
              .catch((err) => alert(err.message));
          })
          .catch((err) => Error(err));
        return;
      }

      if (err.toString().includes("auth/invalid-email")) {
        setError("Please enter a valid email address");
      } else if (err.toString().includes("auth/network-request-failed")) {
        setError(
          "Could not reach server. Please check your network connectivity.",
        );
      } else {
        setError(err.message);
      }
    }
  };

  return (
    <>
      <div className={styles.container2}>
        <h1 style={{ marginBottom: "10px" }}>All Reviews Are Anonymous!</h1>
        <h2 style={{ marginBottom: "10px" }}>
          To make sure all reviews are left by Tufts students, please signup
          with your Tufts email
        </h2>
        <h2 style={{ fontWeight: "200", fontSize: "13px" }}>
          Please use format <span> Tufts_Username</span>@tufts.edu.
        </h2>
        <h2 style={{ fontWeight: "200", fontSize: "13px" }}>
          Ex: jsmith03@tufts.edu
        </h2>
        {error && (
          <Alert
            onClose={() => setError("")}
            severity="warning"
            sx={{
              width: "90%",
              marginBottom: "5px",
              marginTop: "5px",
            }}
          >
            {error}
          </Alert>
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Control
              required
              pattern="^[^.]+@tufts\.edu"
              type="email"
              placeholder="Email address"
              onChange={(e) => validateEmail(e)}
            />
          </Form.Group>
          {/* <Form.Group controlId="formBasicPassword">
            <Form.Control
              type="password"
              placeholder="Create a Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group> */}
          <Button className={styles.button} variant="primary" type="Submit">
            Sign up
          </Button>
        </Form>
      </div>
    </>
  );
};

export default Signup;
